<template>
  <div class="hello">
    <img  alt="2kyun logo" src="https://7175-quaner-5glojoz7582f6d0a-1323192353.tcb.qcloud.la/temp/qrcode/index.png">
    <h1>{{ msg }}</h1>
    <h3>微信扫码获取更多服务</h3>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  margin-top: 10%;
}
h3 {
  margin: 20px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
